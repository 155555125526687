export class LoadDrb {
  public static readonly type = '[Drb] Load Drb';
  constructor(public bundle_name: string) {}
}

export class LoadDrbByVersion {
  public static readonly type = '[Drb] Load Version Of Drb';
  constructor(public version: string, public bundle_name: string) {}
}

export class LoadDrbVersions {
  public static readonly type = '[Drb] Load Drb Versions';
  constructor(public bundle_name: string) {}
}

export class LoadDrbSuccess {
  public static readonly type = '[Drb] Load Drb SUCCESS';
  constructor(public payload: any) {}
}

export class LoadDrbByVersionSuccess {
  public static readonly type = '[Drb] Load Version Of Drb SUCCESS';
  constructor(public payload: any) {}
}
export class LoadDrbVersionsSuccess {
  public static readonly type = '[Drb] Load Drb Version SUCCESS';
  constructor(public payload: any, public bundle_name: string) {}
}

export class LoadDiff {
  public static readonly type = '[Drb] Load Drb Diff';
  constructor(public bundle_name: string) {}
}

export class LoadDiffSuccess {
  public static readonly type = '[Drb] Load Drb Diff SUCCESS';
  constructor(public payload: any) {}
}
export class LoadDrbFail {
  public static readonly type = '[Drb] Load Drb FAIL';
  constructor() {}
}

export class LoadDrbByVersionFail {
  public static readonly type = '[Drb] Load Version Of Drb FAIL';
  constructor() {}
}

export class LoadDrbVersionsFail {
  public static readonly type = '[Drb] Load Drb Version FAIL';
  constructor() {}
}

export class LoadDiffFail {
  public static readonly type = '[Drb] Load Drb Diff FAIL';
  constructor() {}
}

export class ReloadDrb {
  public static readonly type = '[Drb] Reload Drb';
  constructor() {}
}

export class ReloadDrbSuccess {
  public static readonly type = '[Drb] Reload Drb SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadDrbFail {
  public static readonly type = '[Drb] Reload Drb FAIL';
  constructor() {}
}

export class PutDrb {
  public static readonly type = '[Drb] Put Drb';
  constructor(public payload: any) {}
}

export class PostDrb {
  public static readonly type = '[Drb] Post Drb';
  constructor(public payload: any, public bundle_name: string) {}
}

export class SaveComplete {
  public static readonly type = '[Drb] Save Complete';
  constructor(public data: any) {}
}
export class SaveXliffState {
  public static readonly type = '[Drb] Save Strings State';
  constructor(public data: any, public bundle_name: string) {}
}

export class SaveSourceFilesState {
  public static readonly type = '[Drb] Save Source Files State';
  constructor(public data: any, public bundle_name: string) {}
}
export class SaveSourceImagesState {
  public static readonly type = '[Drb] Save Source Images State';
  constructor(public data: any, public bundle_name: string) {}
}

export class SaveSourceLinksState {
  public static readonly type = '[Drb] Save Source Links State';
  constructor(public data: any, public bundle_name: string) {}
}

export class SaveSourceVideosState {
  public static readonly type = '[Drb] Save Source Videos State';
  constructor(public data: any, public bundle_name: string) {}
}

export class SaveDrbState {
  public static readonly type = '[Drb] Save Drb State';
  constructor(public data: any) {}
}
export class SaveFail {
  public static readonly type = '[Drb] Save Fail';
  constructor(public payload: any) {}
}

export class UpdateDrb {
  public static readonly type = '[Drb] Update Drb';
  constructor(public payload: any) {}
}

export class UpdateDrbSuccess {
  public static readonly type = '[Drb] Update Drb SUCCESS';
  constructor(public payload: any) {}
}

export class BuildDrb {
  public static readonly type = '[Drb] Build Drb';
  constructor(public bundle_name: any) {}
}

export class BuildDrbSuccess {
  public static readonly type = '[Drb] Build Drb SUCCESS';
  constructor(public payload: any) {}
}

export class BuildDrbFail {
  public static readonly type = '[Drb] Build Drb FAIL';
  constructor() {}
}

export class NoDrbDraft {
  public static readonly type = '[Drb] No Drb Draft';
  constructor() {}
}

export class NoDrbDiff {
  public static readonly type = '[Drb] No Drb Diff';
  constructor() {}
}

export class DeleteDrbItem {
  public static readonly type = '[Drb] Delete Drb Item';
  constructor() {}
}

export class DeleteDrbItemSuccess {
  public static readonly type = '[Drb] Delete Drb Item';
  constructor() {}
}

export class loadDrbAssociatedItems {
  public static readonly type = '[Drb] Load Drb associated items';
  constructor(public drb_id: any) {}
}

export class loadDrbAssociatedItemsFail {
  public static readonly type = '[Drb] Load Drb associated items FAIL';
  constructor() {}
}
export class loadDrbAssociatedItemsSuccess {
  public static readonly type = '[Drb] Load Drb associated items SUCCESS';
  constructor(public payload: any) {}
}

export class LoadDrbIds {
  public static readonly type = '[Drb] Load Drb IDs';
  constructor() {}
}

export class LoadDrbIdsFail {
  public static readonly type = '[Drb] Load Drb IDs FAIL';
  constructor(public payload: any) {}
}

export class LoadDrbIdsSuccess {
  public static readonly type = '[Drb] Load Drb IDs SUCCESS';
  constructor(public payload: any) {}
}

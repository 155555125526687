import { NgModule } from '@angular/core';

import { TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbMenuModule,
  NbMenuService,
  NbSelectModule,
  NbSidebarService,
  NbTimepickerModule,
  NbToastrModule,
  NbTooltipModule,
  NbWindowModule,
} from '@nebular/theme';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { TagInputModule } from 'ngx-chips';
import { environment } from 'src/environments/environment';
import { MatNativeDateModule } from '@matheo/datepicker/core';
import { CampaignsModule } from '../app/components/campaigns/campaigns.module';
import { InsightsModule } from '../app/components/insights/insights.module';
import { SharedModule } from '../app/shared/shared.module';
import { SurveysModule } from './components/surveys/surveys.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesComponent } from './@theme/pages/pages.component';
import { CommonModules } from './common-modules.module';
import { ModalContainerComponent } from './components/campaigns/campaign-table/view-campaign/modal-container/modal-container.component';
import { CampaignState } from './components/campaigns/redux/campaign.state';
import { ContentComponent } from './components/content/content.component';
import { EditContentComponent } from './components/content/edit-content/edit-content.component';
import { ContentState } from './components/content/redux';
import { ViewContentComponent } from './components/content/view-content/view-content.component';
import { InsightsState } from './components/insights/redux';
import { QaTestingModule } from './components/qa-testing/qa-testing.module';
import { QaState } from './components/qa-testing/redux';
import { SurveysState } from './components/surveys/redux';
import { AmplifyAuthGuardService } from './core/guards/amplify-auth-guard.service';
import { AmplifyService } from './core/services/amplify.service';
import { UserService } from './core/services/user.service';
import { NebularModule } from './@theme/nebular/nebular.module';
import { ChartsModule } from './@theme/charts/charts.module';
import { BoldPipe } from './shared/custom-pipe/bold.pipe';
import { TimestampDatePipe } from './shared/custom-pipe/timestamp-date.pipe';
import { CogwheelState } from './shared/ngx-cogwheel/redux';
import { TableSpinnerState } from './shared/ngx-table/ngx-table/redux/table-spinner.state';
import { RouterState } from './shared/redux/router-state';
import { SpinnerState } from './shared/spinner/redux/spinner.state';
import { LayoutService } from './@theme/components/utils/layout.service';
import { FormatPipe } from './shared/custom-pipe/format.pipe';
import { DrbModule } from './components/drbs/drbs.module';
import { DrbState, DrbIdsState } from './components/drbs/redux';
import { RobotFamilyMetadataState } from './components/robot-family-metadata/redux';
import { RobotFamilyMetadataModule } from './components/robot-family-metadata/robot-family-metadata.module';
import { CleanScoreState } from './components/qa-testing/clean-score/redux';
import { MessMakersState } from './components/qa-testing/mess-makers/redux';
import { CreateScriptedNavComponent } from './components/scripted-nav/create-scripted-nav/create-scripted-nav/create-scripted-nav.component';

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    EditContentComponent,
    ViewContentComponent,
    PagesComponent,
    CreateScriptedNavComponent,
    ModalContainerComponent
  ],
  imports: [
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CampaignsModule,
    InsightsModule,
    RobotFamilyMetadataModule,
    SurveysModule,
    SharedModule,
    ChartsModule,
    CommonModules,
    NebularModule,
    QaTestingModule,
    DrbModule,
    NbMenuModule.forRoot(),
    NgxsModule.forRoot(
      [
        InsightsState,
        SpinnerState,
        CogwheelState,
        TableSpinnerState,
        ContentState,
        QaState,
        CampaignState,
        SurveysState,
        RouterState,
        DrbState,
        DrbIdsState,
        RobotFamilyMetadataState,
        CleanScoreState,
        MessMakersState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Insight Engine UI' }),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbListModule,
    NbDatepickerModule.forRoot(),
    NbAccordionModule,
    NbTooltipModule,
    NbListModule,
    TagInputModule,
    NbWindowModule.forRoot(),
    NbTimepickerModule.forRoot()
  ],
  providers: [
    LayoutService,
    NbMenuService,
    NbSidebarService,
    TitleCasePipe,
    FormatPipe,
    BoldPipe,
    UserService,
    AmplifyService,
    AmplifyAuthGuardService,
    TimestampDatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

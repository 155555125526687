/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Select, Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import {
  BuildDrbFail,
  BuildDrbSuccess,
  LoadDiffFail,
  LoadDiffSuccess,
  LoadDrb,
  LoadDrbByVersionFail,
  LoadDrbByVersionSuccess,
  LoadDrbSuccess,
  LoadDrbVersionsSuccess,
  NoDrbDiff,
  NoDrbDraft,
  SaveComplete,
  SaveFail,
  loadDrbAssociatedItemsFail,
  loadDrbAssociatedItemsSuccess,
  LoadDrbIdsFail,
  LoadDrbIdsSuccess
} from 'src/app/components/drbs/redux/drb.actions';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { AmplifyServiceBuilder } from './amplify.service.builder';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';
import { Drb } from 'src/app/models/drb-models/drb';
import { Auth } from '@aws-amplify/auth';
import { CampaignState } from 'src/app/components/campaigns/redux';
import { Observable } from 'rxjs';
import { ContentState } from 'src/app/components/content/redux';
import { Campaign } from 'src/app/models/campaign';
import { Content } from 'src/app/models/content';

@Injectable({ providedIn: 'root' })
export class DrbService {
  @Select(CampaignState.campaign) public data_campaigns$: Observable<any[]>;
  @Select(ContentState.content) public data_content$: Observable<any[]>;
  public drb_api = 'drb_api';
  public campaigns: Campaign[];
  public contents: Content[];

  constructor(
    private store: Store,
    private toastrService: NbToastrService,
    private formatDatePipe: TimestampDatePipe,
    private cogWheelService: CogwheelService
  ) {

    this.data_campaigns$.subscribe((data) => (this.campaigns = data));
    this.data_content$.subscribe((data) => (this.contents = data));
  }

  public buildKeysForTable(items: any[], allKeys, type) {
    if (items.length > 0) {
      this.cogWheelService.buildKeysForTable(items, allKeys, this.store, type, false);
    }
  }

  public setupAffectedItems(item: any, env: string) {

    if('payload' in item) {
      const payload = item.payload;
      if(env === 'stage') {
        let stageCampaigns = [];
        let stageContent = [];
        if('stage_campaign_ids' in payload || 'stage_content_ids' in payload) {

            stageCampaigns = this.campaigns.filter(
              (campaign) => payload.stage_campaign_ids.includes(campaign.id.toString())
              );
            stageContent = this.contents.filter(
              (content) => payload.stage_content_ids.includes(content.content_id)
              );

          return [stageCampaigns, stageContent];
        }
      } else if(env === 'prod'){
        if('prod_campaign_ids' in payload || 'prod_content_ids' in payload) {

          return [payload.prod_campaign_ids, payload.prod_content_ids];
        }
      }
    } else {
      return [[], []];
    }
  }

  public mapResponse(response) {
    this.buildKeysForTable(response, [], 'drb');
    const localResponse = JSON.parse(JSON.stringify(response));

    //Add readable format for timestamp attributes
    localResponse.xliff.en.forEach((element) => {
      element.local_created_ts = this.formatDatePipe.transform(element?.created_ts, 'timestampToDate');
      element.local_last_updated_ts = this.formatDatePipe.transform(element?.last_updated_ts, 'timestampToDate');
    });

    return localResponse;
  }

  public async loadDrb(bundle_name: string) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.loadDrbAmplify(token, bundle_name);
    });
  }

  public loadDrbAmplify(token, bundle_name, reload?) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, null, { token, version: 'draft', bundle_name});
    API.get(this.drb_api, `/${environment.stage}/drb`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show('', 'Fetching Drb Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadDrbSuccess(this.mapResponse(response)));
      })
      .catch((error) => {
        if (error.response.data.error === 'Draft version not found') {
          this.store.dispatch(NoDrbDraft);
        } else {
          this.toastrService.show('', 'Fetching Drb Failed', {
            status: 'danger'
          });
        }
      });
  }

  public async loadDrbByVersion(version: string, bundle_name: string) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.loadDrbByVersionAmplify(token, version, bundle_name);
    });
  }


  public loadDrbByVersionAmplify(token, version, bundle_name, reload?) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, null, { token, version, bundle_name });
    API.get(this.drb_api, `/${environment.stage}/drb`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show('', 'Fetching Drb ' + version + ' Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadDrbByVersionSuccess(this.mapResponse(response)));
      })
      .catch((error) => {
        this.toastrService.show('', 'Fetching Version Of Drb Failed', {
          status: 'danger'
        });
        this.store.dispatch(new LoadDrbByVersionFail());
      });
  }

  public loadDrbAssociatedItems(drb_id: string) {

    const requestData = AmplifyServiceBuilder(this.drb_api, false, null);

    API.get(this.drb_api, `/${environment.stage}/associated-resources?drb_id=${drb_id}`, requestData)
      .then((response) => {
          this.toastrService.show('', 'Fetching Drb Associated Items Complete', {
            status: 'success'
          });
        this.store.dispatch(new loadDrbAssociatedItemsSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show('', 'Fetching Drb Associated Items Failed', {
          status: 'danger'
        });
        this.store.dispatch(new loadDrbAssociatedItemsFail());
      });
  }


  public putDrbAmplify(newDrbDraft: Drb) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, newDrbDraft);
    API.put(this.drb_api, `/${environment.stage}/drb`, requestData)
      .then((response) => {
        this.store.dispatch(new SaveComplete(this.mapResponse(newDrbDraft)));
      })
      .catch((error) => {
        this.store.dispatch(new SaveFail(error));
      });
  }

  public postDrbAmplify(newDrbDraft: Drb, bundle_name: string) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, newDrbDraft);
    API.post(this.drb_api, `/${environment.stage}/drb`, requestData)
      .then((response) => {
        this.store.dispatch(new LoadDrb(bundle_name));
      })
      .catch((error) => {
        this.store.dispatch(new SaveFail(error));
      });
  }

  public async loadDrbVersions(bundle_name: string) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.loadDrbVersionsAmplify(token, bundle_name);
    });
  }

  public loadDrbVersionsAmplify(token, bundle_name: string, reload?) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, null, { token, all: true, bundle_name });

    API.get(this.drb_api, `/${environment.stage}/drb`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show('', 'Fetching Drb Versions Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadDrbVersionsSuccess(response, bundle_name));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show('', 'Fetching Drb Versions Failed', {
          status: 'danger'
        });
      });
  }

  public async loadDiff(bundle_name) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.loadDiffAmplify(token, bundle_name);
    });
  }

  public loadDiffAmplify(token, bundle_name, reload?) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, null, { token, bundle_name });
    const endpoint = environment.stage === 'prod' ? 'validate-prod' : 'validate';
    API.get(this.drb_api, `/${environment.stage}/${endpoint}`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show('', 'Fetching Drb Diff Complete', {
            status: 'success'
          });
        }
        const data = response;
        this.store.dispatch(new LoadDiffSuccess(data));
      })
      .catch((error) => {
        if (error.response.data === 'No DRBs available for validation') {
          this.store.dispatch(NoDrbDiff);
        } else {
          this.toastrService.show('', 'Fetching Drb Diff Failed', {
            status: 'danger'
          });
          this.store.dispatch(new LoadDiffFail());
        }
      });
  }

  public async buildDrb(bundle_name) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.buildDrbAmplify(bundle_name, token);
    });
  }

  public buildDrbAmplify(bundle_name, token, reload?) {
    const requestData = AmplifyServiceBuilder(this.drb_api, false, {bundle_name}, { token });
    const endpoint = environment.stage === 'prod' ? 'deploy-prod' : 'build';
    API.post(this.drb_api, `/${environment.stage}/${endpoint}`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show('', 'Building Drb Started', {
            status: 'success'
          });
        }
        this.store.dispatch(new BuildDrbSuccess({ draft_version: 'SUCCEDDED' }));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show('', 'Building Drb Failed', {
          status: 'danger'
        });
        this.store.dispatch(BuildDrbFail);
      });
  }

  public loadDrbIds() {
    API.get(this.drb_api, `/${environment.stage}/drb-ids`, '')
      .then((response
          ) => {
          this.toastrService.show('', 'Fetching Drb IDs Complete', {
            status: 'success'
          });
        this.store.dispatch(new LoadDrbIdsSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show('', 'Fetching Drb IDs Failed', {
          status: 'danger'
        });
        this.store.dispatch(new LoadDrbIdsFail(error));
      });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbTagModule } from '@nebular/theme';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionButton, ActionDisplay, Content, Fields, PayloadRecord } from 'src/app/models/content';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from '../../../shared/redux';
import { PreviewHtmlComponent } from '../preview-html/preview-html.component';
import { PostContent, PostSaveComplete, UpdateContentSuccess } from '../redux';
import { DestinationType } from './../../../models/content';
import { UpdateContent } from './../redux/content.actions';
import { LoadDrbIds, DrbIdsState } from '../../drbs/redux';

@Component({
  selector: 'app-edit-content',
  templateUrl: './edit-content.component.html',
  styleUrls: ['./edit-content.component.css', '../../../shared/common_syles.css']
})
export class EditContentComponent implements OnInit {
  @ViewChild('contentForm', { static: false }) public contentForm: NgForm;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(DrbIdsState.allDrbIds) public allDrbIds$: Observable<any>;

  public content: Content;
  public drbIds;
  public disableRefreshButton = false;
  public title: string;
  public action_button_enabled = false;

  public showSpinner = false;
  public invalidContent = false;
  public validationMessage = '';

  public delivery_channel;

  public actionDisplays = ActionDisplay;
  public destinationTypes = DestinationType;
  public fields = Fields;
  public CategoryTitles= [
    {category: 'notification_center_icon_special_offer', id: 'just_for_you_category_title_id_dmc', text: 'Just for you'},
    {category: 'notification_center_icon_new_product', id: 'iseries_upsell_category_title_id_dmc', text: 'New Product'},
    {category: 'notification_center_icon_tips_normal', id: 'tips_dmc', text: 'Tips'},
    {category: 'notification_icon_maps_dmc', id: 'map_suggestion_dmc', text: 'Map Suggestion'},
    {category: 'notification_icon_maps_dmc', id: 'map_update_dmc', text: 'Map Update'},
    {category: 'notification_center_icon_tips', id: 'tips_dmc', text: 'Tips'},
    {category: 'notification_center_icon_maintenance', id: 'to_dos_dmc', text: 'Maintenance'},
    {category: 'notification_center_icon_feedback', id: 'feedback_dmc', text: 'Feedback'},
    {category: 'all', id: 'special_offer_category_title_id_dmc', text: 'Special Offers'}
  ];

  public categoryIconId = [
    'notification_center_icon_special_offer',
    'notification_center_icon_new_product',
    'notification_center_icon_tips_normal',
    'notification_icon_maps_dmc',
    'notification_center_icon_tips',
    'notification_center_icon_maintenance',
    'notification_center_icon_feedback',
    'notification_center_beyond_cleaning'
  ];
  public actionType = ['content', 'suggested_schedule', 'scripted_navigation', 'webview'];
  public isScriptedNavigationEnabled = false;
  public displayType = ['inline', 'separate_view', 'modal_view'];
  public versions = [];

  public messageType = ['message_center', 'contextual_message', 'banner', 'personalized_offer'];
  public message_type = [];

  public username = '';

  constructor(
    protected ref: NbDialogRef<EditContentComponent>,
    private store: Store,
    private dialogService: NbDialogService,
    private actions$: Actions
  ) {}

  public ngOnInit() {
    if (this.content.action_button && this.content.action_button.length > 0) {
      this.action_button_enabled = true;

      const helperArray = [];
      const clonedActionButton: ActionButton = new ActionButton();
      Object.assign(clonedActionButton, this.content.action_button[0]);
      helperArray.push(clonedActionButton);
      this.content.action_button = helperArray;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.content = <Content>Content.clone(this.content);
    this.data_username$.subscribe((data) => (this.username = data));
    this.store.dispatch(new LoadDrbIds());
    this.allDrbIds$.subscribe((data) => {
        this.drbIds = data;
    });

    this.generateVersions();

    if (this.title === 'Edit') {
      if (this.content.payload_records) {
        this.content.content_html = this.htmlDecode(this.content.content_html);
        const array = [];
        this.content.payload_records.forEach((element) => {
          const objClone = { ...element };
          if (objClone.payload_record_type === 'message_center') {
            this.message_type.push('message_center');
          }
          if (objClone.payload_record_type === 'contextual_message') {
            this.message_type.push('contextual_message');
          }
          if (objClone.payload_record_type === 'banner') {
            this.message_type.push('banner');
          }
          if (objClone.payload_record_type === 'personalized_offer') {
            this.message_type.push('personalized_offer');
          }
          objClone.content_html = this.htmlDecode(objClone.content_html);
          array.push(objClone);
        });
        this.content.payload_records = array;
      }
      this.actions$.pipe(ofActionCompleted(UpdateContentSuccess)).subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
    } else if (this.title === 'New' || this.title === 'Clone') {
      this.content.payload_records = [];
      this.actions$.pipe(ofActionCompleted(PostSaveComplete)).subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
    }

    if (this.content && this.content.action_type === 'scripted_navigation') {
      this.isScriptedNavigationEnabled = true;
    }
  }

  public generateVersions() {
    const min = 500;
    const max = 700;
    let current = min;
    this.versions.push(min.toString().match(/.{1}/g).join('.'));

    while (current < max) {
      current = current + 10;
      const string = current.toString().match(/.{1}/g).join('.');
      this.versions.push(string);
    }
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public validate(id: string) {
    if (!this.contentForm || !this.contentForm.form.get(id) || !this.contentForm.form.get(id).hasError) {
      return null;
    }

    return this.contentForm.form.get(id);
  }

  public previewAlert(html: any) {
    this.dialogService.open(PreviewHtmlComponent, {
      context: {
        html
      }
    });
  }

  public save() {
    this.content.payload_records.forEach((element) => {
      if (element.content_html) {
        element.content_html = this.escapeHtml(element.content_html);
      }

      for (const property of this.fields) {
        if (element[property] !== undefined && element[property] !== null) {
          if (element[property].toString().length === 0) {
            element[property] = null;
          }
        }
      }
    });
    if (this.title === 'Clone') {
      delete this.content.content_id;
      delete this.content.content_version;
      delete this.content.content_key;
    }
    if (this.title === 'Edit') {
      this.content.last_updated_by = this.username;
      this.store.dispatch(new UpdateContent(this.content));
    } else {
      this.content.created_by = this.username;
      this.content.last_updated_by = this.username;
      this.store.dispatch(new PostContent(this.content));
    }
  }

  public escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  public htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;

    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public onSelectionChange(event) {
    switch (event) {
      case 'notification_center_icon_special_offer':
        this.content.category_title_id = 'special_offer_category_title_id_dmc';
        this.content.category_title_text = 'Special Offers';

        return true;
        case 'notification_center_icon_new_product':
          this.content.category_title_id = 'iseries_upsell_category_title_id_dmc';
          this.content.category_title_text = 'New Product';

          return true;
        case 'notification_center_icon_tips_normal':
          this.content.category_title_id = 'tips_dmc';
          this.content.category_title_text = 'Tips';

          return true;
      case 'notification_center_icon_maintenance':
        this.content.category_title_id = 'to_dos_dmc';
        this.content.category_title_text = 'To Dos';

        return true;
      case 'notification_center_icon_tips':
        this.content.category_title_id = 'tips';
        this.content.category_title_text = 'Tips';

        return true;
      case 'notification_icon_maps_dmc':
        this.content.category_title_id = 'map_suggestion_dmc';
        this.content.category_title_text = 'Map Suggestion';

        return true;
      case 'notification_center_icon_feedback':
        this.content.category_title_id = 'feedback_dmc';
        this.content.category_title_text = 'Feedback';

        return true;
      default:
        return false;
    }
  }


  public onCategoryTitleSelect(event) {
    this.CategoryTitles.forEach((category) => {
      if(category.text === event){
        this.content.category_title_id = category.id;
      }
    });
  }


  public actionButtonEvent(event: any) {
    if (event) {
      this.action_button_enabled = true;
      this.content.action_button = [];
      this.content.action_button.push(new ActionButton());
    } else {
      this.content.action_button = [];
      this.action_button_enabled = false;
    }
  }

  public shouldButtonEnabled() {
    if (this.content.payload_records) {
      if (this.content.payload_records.some((item) => item.payload_record_type === 'contextual_message')) {
        if (this.content.active_parent_uri_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public messageTypeAdd(event) {
    if (this.content.payload_records === null || undefined) {
      this.content.payload_records = [];
    }
    if (event === 'message_center') {
      const payload_record = new PayloadRecord();
      payload_record.payload_record_type = 'message_center';
      payload_record.action_display = 'template_view';
      this.content.payload_records.push(payload_record);
    }
    if (event === 'contextual_message') {
      const payload_record = new PayloadRecord();
      payload_record.payload_record_type = 'contextual_message';
      payload_record.action_display = 'half_modal_view';
      this.content.payload_records.push(payload_record);
    }
    if (event === 'banner') {
      const payload_record = new PayloadRecord();
      payload_record.payload_record_type = 'banner';
      this.content.payload_records.push(payload_record);
    }
    if (event === 'personalized_offer') {
      const payload_record = new PayloadRecord();
      payload_record.payload_record_type = 'personalized_offer';
      payload_record.action_display = 'personalized_template_view';
      this.content.payload_records.push(payload_record);
    }
  }

  public messageTypeRemove(event) {
    this.content.payload_records = this.content.payload_records.filter((e) => e.payload_record_type !== event.value);
    if (event.value === 'contextual_message') {
      this.content.active_parent_uri_id = null;
    }
  }

  public messageTypeClear() {
    this.content.payload_records = [];
  }

  public keyPressNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();

      return false;
    } else {
      return true;
    }
  }

  public availableCategories() {
    return this.CategoryTitles.filter((category) => category.category === this.content.category_icon_id ||category.category === 'all');
  }

  public onActionTypeChange() {
    this.isScriptedNavigationEnabled = this.content.action_type === 'scripted_navigation';
    if (!this.isScriptedNavigationEnabled) {
      this.content.payload_records[0].script_id = '';
      this.content.payload_records[0].script_id_display_order = null;
    }
  }

  public addTag(tag: string) {
    /* https://github.com/ng-select/ng-select/issues/809 */
    return tag;
}

  public getDrbIdsByType(type) {
      if (type === 'string') {
          return this.drbIds.stringIds;
      }
      else if (type === 'image') {
          return this.drbIds.imageIds;
      }
      else if (type === 'link') {
          return this.drbIds.linkIds;
          }
      else if (type === 'video') {
              return this.drbIds.videoIds;
          }
      else if (type === 'file') {
              return this.drbIds.fileIds;
          }
  }

  public refresh() {
    this.disableRefreshButton = true;
    this.store.dispatch(new LoadDrbIds());

    setTimeout(() => {
      this.disableRefreshButton = false;
    }, 3000);
  }


}
